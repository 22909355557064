import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.jsx";

import { IS_APP } from "@/__main__/constants.mjs";
import { GAME_PREFERRED_NAME_MAP, I18N_PLACEHOLDER } from "@/app/constants.mjs";
import definition from "@/game-eft/definition.mjs";
import { LINKED_ACCOUNT_EMPTY_STATE_ROBOT } from "@/hub-settings/constants.mjs";
import { launchGame } from "@/library/actions.mjs";
import Container from "@/shared/ContentContainer.jsx";
import { devError } from "@/util/dev.mjs";
import { useInstalledGames } from "@/util/game-system-hooks.mjs";

const gameSymbol = definition.gameSymbol;

export default function Launch() {
  const installedGames = useInstalledGames();
  const [launchingState, setLaunchingState] = useState(false);
  const { t } = useTranslation();
  const isInstalled = installedGames.includes(gameSymbol);
  const doLaunch = useCallback(
    (gameSymbol) => {
      if (launchingState) return;
      setLaunchingState(true);
      setTimeout(() => {
        setLaunchingState(false);
      }, 5 * 1000);
      launchGame(gameSymbol).catch(devError);
    },
    [launchingState],
  );
  if (!IS_APP) return null;
  const gameName = GAME_PREFERRED_NAME_MAP[gameSymbol]
    ? t(...GAME_PREFERRED_NAME_MAP[gameSymbol])
    : I18N_PLACEHOLDER;
  let launchLabel = isInstalled
    ? t("common:launchGame", "Launch Game")
    : t("common:library.installGame", "Install Game");
  if (launchingState) launchLabel = t("common:loading", "Loading...");
  return (
    <Container>
      <div className="flex column align-center gap-4">
        <video
          loop
          autoPlay
          preload="metadata"
          controlsList="nodownload"
          muted
          style={{ width: "var(--sp-30)", height: "var(--sp-30)" }}
        >
          <source
            src={`${LINKED_ACCOUNT_EMPTY_STATE_ROBOT}`}
            type="video/mp4"
          />
        </video>
        <div className="description type-body2">
          {t(
            "common:launchGameLong",
            "Launch {{game}} and log in to the account that you want to connect",
            { game: gameName },
          )}
        </div>
        <Button
          onClick={() => doLaunch(gameSymbol)}
          textClass="type-body2"
          textColor="var(--yellow)"
          size="small"
          disabled={isInstalled ? (launchingState ? true : null) : true}
        >
          {launchLabel}
        </Button>
      </div>
    </Container>
  );
}
